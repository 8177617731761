<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      api-url="/invoice-changed"
      table-height="calc(100vh - 196px)"
      @showDialogProcess="showDialogProcess"
    ></custom-table>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_BILL_WAIT_PROCESS,
  ORDER_SOURCES,
  INVOICE_TAX_STATUS,
} from "@/libs/const";

export default {
  name: "Changed",
  components: {},
  data: () => ({
    columns: [],
    customView: CUSTOM_VIEW_BILL_WAIT_PROCESS,
    sourceOptions: [...ORDER_SOURCES],
    processDialog: false,
    updatingItem: {},
    taxStatusOptions: [...INVOICE_TAX_STATUS],
  }),
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: true,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.date"),
        placeholder: this.$t("labels.date"),
        name: "created_at",
        hasSort: true,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      /* {
        type: "select-employee",
        label: this.$t("labels.account"),
        placeholder: this.$t("labels.account"),
        name: "id_identity",
        hasSort: true,
        sortName: "id_identity",
        key: "identity_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.household_business"),
        placeholder: this.$t("labels.household_business"),
        name: "tax_name",
        hasSort: true,
        sortName: "tax_name",
        key: "tax_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tax_code"),
        placeholder: this.$t("labels.tax_code"),
        name: "tax_code",
        hasSort: true,
        sortName: "tax_code",
        key: "tax_code",
        required: true,
      }, */
      {
        type: "select-filter",
        label: this.$t("labels.order_source"),
        placeholder: this.$t("labels.order_source"),
        name: "source",
        hasSort: false,
        sortName: "source",
        key: "source",
        options: this.sourceOptions,
        required: true,
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_customer_e_marketplace_shop",
        hasSort: true,
        sortName: "customer_e_marketplace_shop_name",
        key: "customer_e_marketplace_shop_name",
        required: true,
        params: {
          "is-check-e-market": false,
        },
      },
      {
        type: "input-filter",
        label: this.$t("labels.invoice_order_changed"),
        placeholder: this.$t("labels.invoice_order_changed"),
        name: "note",
        hasSort: true,
        sortName: "note",
        key: "note",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.invoice_is_adjusted"),
        placeholder: this.$t("labels.invoice_is_adjusted"),
        name: "invoice_no_origin",
        hasSort: true,
        sortName: "invoice_no_origin",
        key: "invoice_no_origin",
        required: true,
      },
      /* {
                type: 'input-filter',
                label: this.$t('labels.invoice_code_cqt'),
                placeholder: this.$t('labels.invoice_code_cqt'),
                name: 'invoice_code_origin',
                hasSort: true,
                sortName: 'invoice_code_origin',
                key: 'invoice_code_origin',
                required: true,
            }, */
      {
        type: "input-filter",
        label: this.$t("labels.adjustment_invoice"),
        placeholder: this.$t("labels.adjustment_invoice"),
        name: "invoice_no",
        hasSort: true,
        sortName: "invoice_no",
        key: "invoice_no",
        required: true,
      },
      /* {
                type: 'input-filter',
                label: this.$t('labels.invoice_code_cqt'),
                placeholder: this.$t('labels.invoice_code_cqt'),
                name: 'invoice_code',
                hasSort: true,
                sortName: 'invoice_code',
                key: 'invoice_code',
                required: true,
            }, */
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "tax_status",
        hasSort: false,
        sortName: "tax_status",
        key: "tax_status",
        options: this.taxStatusOptions,
        required: true,
      },
    ];
    this.columns = [...columns];
  },
  methods: {
    showDialogProcess(item) {
      this.updatingItem = { ...item };
      this.processDialog = true;
    },
    hideDialogProcess() {
      this.updatingItem = {};
      this.processDialog = false;
    },
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
  },
};
</script>
